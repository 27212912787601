<template>
    <div id="app">

      <b-navbar class="py-1 navbar-light" toggleable="lg" type="dark" variant="dark" v-if="isLogged">
      <b-navbar-brand href="#" to="/">
        APPLICASOL
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav >

        <b-navbar-nav class="my-auto">
          <b-nav-item>
              <em><i style="font-size:20px" class="fas fa-user-circle" /></em> {{ username }}
          </b-nav-item>
        </b-navbar-nav>

          <b-nav-item-dropdown href="#" to="/menu">
            <template #button-content>
              <em><i style="font-size:20px" class="fas fa-question-circle" /></em> Aide
            </template>
            <b-dropdown-item href="/fichiers/DictionnaireBDD_ApplicaSol_V2_20140326.pdf" target="_blank">
              Dictionnaire de données
            </b-dropdown-item>
            <b-dropdown-item href="/fichiers/NoticeUtilisation_ApplicaSol_20140326.pdf" target="_blank">
              Notice d'utilisation
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link to="/issues-applicasol">
              Signaler un bug ou une demande d'amélioration
              </router-link>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>        

        <b-navbar-nav class="ml-auto">
          <b-nav-item href="#" @click="logout" class="d-flex justify-content-end">
           Se déconnecter <a href="#" class="signout_icon"><i class="fas fa-sign-out-alt" /></a>
          </b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>

      <div  class="cadreHome">
        <div v-if="isLogged" class="btnSaisir">
          <b-button class="saisir" to="/applications" id="saisirApp">Saisir une application</b-button>
          <b-button class="saisir" to="/methodes" id="saisirMethode">Saisir une méthode</b-button>
        </div>
      <router-view/>
      <div v-if="isLogged" class="logo">
        <b-row>
          <b-col cols="6" md="4" class="logoRmt"><img src="@/assets/logo_rmt.jpg" style="width:100px"></b-col>
          <b-col cols="6" md="4" class="logoInrae"><img src="@/assets/Logo-INRAE.png" style="width:100px"></b-col>
          <b-col cols="6" md="4" class="logoGis"><img src="@/assets/logo-GIS.png" style="width:100px"></b-col>
        </b-row>
      </div>
      </div>

      <div v-if="isLogged" id="footer">Copyright © 2009-{{new Date().getFullYear()}} INRAE, Tous droits réservés</div>
    </div>


</template>

<script>

export default {

  computed:{
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    isLogged : function (){
      return this.$store.getters.isLoggedIn;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    userName:function() {
      return this.$store.state.nom;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    userFirstname:function() {
      return this.$store.state.prenom;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    userEmail:function (){
      return this.$store.state.email;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    username:function(){
      return this.$store.state.username;
    }
  },

  methods:{
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  }

}

</script>

<style>


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#footer {
  color: #2C3E50;
  text-align: center;
  padding-bottom: 2em;
  padding-top: 1em;
  font-size: 1rem;
}
.navigation{
  margin-left: 1em;
  padding-top: 1em;
  margin-right: 1em;
}
.logo{
  padding-top: 1em;
  text-align: center;
}
.logoInrae{
  padding-top: 2em;
}
.logoGis{
  padding-top: 2em;
}
.logoRmt{
  padding-bottom: 1em;
}
/* .cadreHome{
  text-align: justify;
  margin-left: 4em;
  margin-right: 4em;
  background-color: aliceblue;
  border-radius: 30px;
} */
.entete{
  text-decoration: none !important;
  font-size: 3rem;
  font-weight: bold;
  /* color: white; */
  color: #475562;
}
.entete:hover{
  color: white !important;
}
.list{
  text-align: end;
}
.btn-secondary{
  background-color: aliceblue !important;
  color: black !important;
}
/* a.dropdown-item:hover{
  background-color: #008c8e !important;
  color: white !important;
} */
/* .logout{
  color: #ed6e6c !important;
} */

@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }

  .navbar .nav-item:hover .nav-link {
    color: #fff;
  }

  .navbar .nav-item .dropdown-menu.show {
    display: block;
  }

  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}

.signout_icon {
  color: rgba(240, 42, 42, 0.5);
  text-decoration: none;
}

.signout_icon:hover{
  color: red ;
}

</style>