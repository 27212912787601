<template>
  <LoginForm></LoginForm>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm2'

export default {
  name:'Login',
  components:{LoginForm}
}
</script>

<style>

.nav-link1{
  color: white !important;
  font-size: 15px;
  text-align: right;
  margin:auto;
  background-color: #00a3a6;
}
.nav-link1:hover{
  color: black !important;
}
</style>