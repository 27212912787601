import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'
//@ts-ignore
import * as Cookies from 'js-cookie'
//@ts-ignore
import config from '@/config';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      getState: (key) => Cookies.get(key)!==undefined?JSON.parse(Cookies.get(key)):null,
      setState: (key, state) =>Cookies.set(key, JSON.stringify(state),{secure:false} ),
      storage : window.sessionStorage
    })
  ],
  state: {
    username :'',
    idUtilisateur:'',
    token: sessionStorage.getItem('token') || '',
    status:'',
    nom:'',
    prenom : '',
    email: '',
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_setId(state,{id}){
      state.idUtilisateur = id
    },
    auth_setNom(state,{nom}){
      state.nom = nom
    },
    auth_setPrenom(state,{prenom}){
      state.prenom = prenom
    },
    auth_setUsername(state,{username}){
      state.username = username
    },

    auth_success(state, {token, username}) {
      state.status = 'success',
          state.token = token,
          state.username = username
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.username = ''
      state.idUtilisateur = ''
      state.nom = ''
      state.prenom = ''
    },
  },
  getters: {
    isLoggedIn :state => !!state.token,
    authStatus :state => state.status,
    getId : state =>state.idUtilisateur,
    getNom : state => state.nom,
    getPrenom : state => state.prenom,
    getUsername : state => state.username,

  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({ url:`${config.API_URL}authenticate`, data: user, method: 'POST' })
            .then(response => {
              const token = response.data.token
              const username = user.username
              axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
              axios.get(`${config.API_URL}utilisateur/current/`)
                  .then(response => {
                    const id = response.data.principal.id
                    const nom = response.data.principal.name
                    const prenom = response.data.principal.firstName
                    const username = response.data.principal.username

                    commit('auth_setId', {id})
                    commit('auth_setNom', {nom})
                    commit('auth_setPrenom', {prenom})
                    commit('auth_setUsername', {username})

                  })
              commit('auth_success', {token, username})
              resolve(response)
            })
            .catch(error => {
              commit('auth_error')
              sessionStorage.removeItem('token')
              reject(error)
            })
      })
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('logout')
        delete axios.defaults.headers.common['Authorization']
        sessionStorage.clear();
        resolve(Response)
      })
    }
  },
  modules: {
  }
})
