import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.ts'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import {RouteMeta} from 'vue-router'

// @ts-ignore
import config from '@/config';

Vue.use(Router)


const router = new Router ({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/demandemdp',
      name: 'MotDePasseOublie',
      component: () => import('../views/Connection/MotDePasseOublie.vue'),
    },

    {
      path: '/reinitmdp/:token',
      name: 'ResetPassword',
      component: () => import('../views/Connection/ResetPassword.vue'),
      props:true,
    },


    {
      path: '/applications',
      name: 'Applications',
      component: () => import(/* webpackChunkName: "about" */ '../views/Applications.vue'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/methodes',
      name: 'Methodes',
      component: () => import(/* webpackChunkName: "about" */ '../views/Methodes.vue'),
      meta: {
        requiresAuth: true
      }
    },
  ]
})


router.beforeEach((to, from, next) => {
  const { requiresAuth } = to.meta as RouteMeta

  // Si la route nécessite une authentification et l'utilisateur n'est pas connecté, redirige vers "/login"
  if (requiresAuth && !store.getters.isLoggedIn) {
    next('/login')
    return
  }

  // Si l'utilisateur est déjà connecté et essaie d'accéder à "/login" ou "/demandemdp", redirige vers "/"
  if (store.getters.isLoggedIn && (to.path === '/login' || to.path === '/demandemdp')) {
    next('/')
    return
  }

  // Vérifie si la route demandée existe
  const matchedComponents = router.getMatchedComponents(to)
  if (!matchedComponents.length) {
    // La route n'existe pas, redirige vers "/"
    next('/')
    return
  }
  // Sinon, autorise l'accès à la route demandée
  next()
})


export default router
