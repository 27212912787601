<template>
    <div class="container-fluid px-1 px-md-5 px-lg-1 px-xl-5 py-5 mx-auto">
        <div class="card card0 border-0">
            <div class="row d-flex">
                <div class="col-lg-4">
                    <div class="card1 pb-5">
                        <div class="row px-3 justify-content-center mt-4 mb-5 border-line"> 
                            <img src="@/assets/vignette_sol.png" class="image"> 
                        </div>
                    </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card2 card border-0 px-4 py-5">
                            <div class="row mb-4 px-3">
                                <h6 class="mb-0 mr-4 mt-2 welcome-text">Bienvenue sur 
                                    <span class="text-bold">APPLICASOL</span>
                                </h6>
                            </div> 
                        <div class="row px-2 mb-4">
                            <span class="intro-text" >APPLICASOL est un système d’information référençant et facilitant la mise en commun de l’ensemble
                             des applications thématiques réalisées à partir des bases de données cartographiques sur les sols, 
                             ainsi que les méthodes de traitement utilisées.
                             </span>
                        
                    </div>
                    
                    <ValidationObserver ref="observer" v-slot="{handleSubmit}" >
                        <form @submit.prevent="handleSubmit(onSubmit)">
                            
                            <ValidationProvider :rules="{required:true,email:true}" vid="identifiant" name="identifiant" v-slot="{errors}">
                            <div class="row px-3"> <label class="mb-1">
                                <h6 class="mb-0 text-sm">Identifiant (email)</h6>
                                </label> 
                                
                                <b-form-input debounce="2500" v-model="login" type="text" name="email" placeholder="Email"></b-form-input>
                                <span style="color:red;">{{errors[0]}}</span>                                
                            </div>
                            </ValidationProvider>

                            <ValidationProvider deb :rules="{required:true}" v-slot="{errors}" vid="password" name="password">
                            <div class="row px-3"> <label class="mb-1 mt-4">
                                    <h6 class="mb-0 text-sm">Mot de passe</h6>
                                </label> 
                                <input v-model="password" type="password" name="password" placeholder="Mot de passe"> 
                                <span style="color:red">{{errors[0]}}</span>
                            </div>
                            </ValidationProvider>
                            <div class="row px-3 mb-4">                        
                            </div>
                            <div class="row mb-3 px-3"> 
                                <button type="submit" class="btn btn-connect text-center">Se connecter</button> 
                            </div>
                            
                        </form>
                    </ValidationObserver>

                    <div class="row mb-4 px-3">
                        <small class="font-weight-bold"> 
                            <router-link to="demandemdp" class="text-compte " style="color:royalblue;">Mot de passe oublié ?</router-link>
                            </small> 
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-blue ">
            <div class="row px-3">
                <div  class="col-md-6 d-flex justify-content-start">
                    <small class="my-5 ml-5">Copyright &copy; 2009-{{new Date().getFullYear()}} INRAE, Tous droits réservés</small>
                    </div>
                    <div class="d-flex justify-content-end">
                        <small class="my-5 mr-5 ml-5"><img src="@/assets/logo-GIS.png" style="width: 100px;"></small>
                        <small  class="my-5"><img src="@/assets/logo_inrae_blanc.png" style="width: 100px;"></small>
                        <small style="opacity:0.9" class="ml-5 my-4"><img class="" src="@/assets/logo_rmt-fond-blanc.jpg" style="width: 90px;"></small>            
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import {extend} from 'vee-validate'
import { required,email } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Ce champ est obligatoire'
});
extend('email', {
  ...email,
  message: "Le format de l'email saisi est incorrect"
});

export default {
    name:'LoginForm2',    
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data(){
        return{
            login:'',
            password:'',
        }
    },
    methods:{
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        onSubmit(){
        const isValid =  this.$refs.observer.validate();
        const dataUser = {username: this.login, password: this.password}
        if(isValid){
            this.$store
                .dispatch("login", dataUser)
                .then(() => this.$router.push('/'))
                .catch(() =>{
                this.$refs.observer.setErrors({
                    identifiant: ['Identifiant ou mot de passe incorrect.'],
                    password: ['Identifiant ou mot de passe incorrect.']

                });
                this.invalid =true;

                });
        }
        else {
            this.invalid=true;
            window.scrollTo(0,0);
        }
        }        
    }
    
}
</script>


<style scoped>
.card0 {
    box-shadow: 0px 4px 8px 0px #757575;
    border-radius: 0px
}

.card2 {
    margin: 0px 40px
}

.image {
    width: 360px;
    /* height: 280px */
}

.border-line {
    border-right: 1px solid #EEEEEE
}

.welcome-text{
    font-variant: small-caps;
    font-size: 25px;
    color: #95775d;
}

.intro-text{
    color: #475562;
    
}

.text-bold{
    font-weight: bold;
}

.or {
    width: 10%;
    font-weight: bold
}

.text-sm {
    font-size: 14px !important
}

::placeholder {
    color: #BDBDBD;
    opacity: 1;
    font-weight: 300
}


input,
textarea {
    padding: 10px 12px 10px 12px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    margin-bottom: 5px;
    margin-top: 2px;
    width: 100%;
    box-sizing: border-box;
    color: #2C3E50;
    font-size: 14px;
    letter-spacing: 1px
}

input:focus,
textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #304FFE;
    outline-width: 0
}

button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
}

a {
    /* color: inherit; */
    
    text-decoration: underline;
    cursor: pointer
}

.btn-connect {
    /* background-color: #648d3b; */
    /* background-color: #8a7562; */
    background-color: #475562;
    width: 150px;
    color: #fff;
    border-radius: 2px
}

.btn-connect:hover {
    background-color: #000;
    cursor: pointer
}

.bg-blue {
    color: #fff;
    /* background-color: #008c8e */
    background-color:#95775d ;
}

@media screen and (max-width: 991px) {

    .image {
        width: 300px;
        height: 220px
    }

    .border-line {
        border-right: none
    }

    .card2 {
        border-top: 1px solid #EEEEEE !important;
        margin: 0px 15px
    }
}

</style>